var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.settingsComponent },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "10" }
            },
            [
              _c("h3", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("settings.folder.corporateIdentity.name")) +
                    " "
                )
              ]),
              _c(
                "b-dropdown",
                {
                  staticClass: "context-menu",
                  attrs: {
                    "toggle-class": "dot-button",
                    "no-caret": "",
                    "no-flip": "",
                    right: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function() {
                        return [
                          _c("b-icon", {
                            attrs: {
                              icon: "three-dots-vertical",
                              "aria-hidden": "true",
                              scale: "1"
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("b-dropdown-item", { on: { click: _vm.copySettings } }, [
                    _vm._v(" " + _vm._s(_vm.$t("dropdowns.copySettings")) + " ")
                  ]),
                  _vm.settingsCopied
                    ? _c(
                        "b-dropdown-item",
                        { on: { click: _vm.pasteSettings } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("dropdowns.pasteSettings")) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c(
              "div",
              {
                staticClass: "override-children",
                class: _vm.toggleAllCiChildrenOverride ? "expanded" : ""
              },
              [
                _c(
                  "h4",
                  {
                    staticClass:
                      "px-4 py-3 m-0 text-uppercase d-flex justify-content-between align-items-center"
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.folder.childrenOverride.label"))
                      )
                    ]),
                    _c("b-form-checkbox", {
                      attrs: {
                        id: "toggle-all-children-override",
                        name: "toggle-all-children-override",
                        size: "lg",
                        value: true,
                        "unchecked-value": false
                      },
                      on: {
                        change: function($event) {
                          return _vm.toggleOverrideAllChildrenBox($event)
                        }
                      },
                      model: {
                        value: _vm.toggleAllCiChildrenOverride,
                        callback: function($$v) {
                          _vm.toggleAllCiChildrenOverride = $$v
                        },
                        expression: "toggleAllCiChildrenOverride"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-collapse",
                  { attrs: { id: "collapse-override-ci-children" } },
                  [
                    _c("div", { staticClass: "px-4 py-3" }, [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("settings.folder.childrenOverride.desc")
                          )
                        }
                      }),
                      _c("ul", { staticClass: "pl-0" }, [
                        _c(
                          "li",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: {
                                  id: "override-all-ci-children",
                                  name: "overrideAllCiChildren",
                                  size: "lg",
                                  value: true,
                                  "unchecked-value": false
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.overrideAllChildren($event)
                                  }
                                },
                                model: {
                                  value: _vm.overrideAllCiChildren,
                                  callback: function($$v) {
                                    _vm.overrideAllCiChildren = $$v
                                  },
                                  expression: "overrideAllCiChildren"
                                }
                              },
                              [_vm._v(" " + _vm._s("All CI Settings") + " ")]
                            ),
                            _c(
                              "ul",
                              _vm._l(_vm.allChildrenCisArray, function(ciItem) {
                                return _c(
                                  "li",
                                  { key: ciItem.handle },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          id: ciItem.handle,
                                          name: ciItem.handle,
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeOverrideChildren(
                                              ciItem.name,
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.overrideChildrenCis[
                                              ciItem.handle
                                            ],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.overrideChildrenCis,
                                              ciItem.handle,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "overrideChildrenCis[ciItem.handle]"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                `settings.folder.childrenOverride.${ciItem.handle}Label`
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                _vm._s(_vm.$t("settings.folder.corporateIdentity.logoLabel"))
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("image-upload", {
                attrs: {
                  "image-object": _vm.corporateIdentity.logo.image,
                  allowImageEdit: true,
                  allowImageFocalpointEdit: true
                },
                on: { "upload-image-object": _vm.updateUploadImageObject }
              }),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.folder.corporateIdentity.logoDesc")
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                _vm._s(_vm.$t("settings.folder.corporateIdentity.faviconLabel"))
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("image-upload", {
                attrs: {
                  "image-object": _vm.corporateIdentity.favicon.image,
                  "accepted-file-types":
                    "image/x-icon,image/vnd.microsoft.icon," +
                    "image/ico,image/icon,text/ico,application/ico",
                  "file-poster-height": "44px",
                  allowImageEdit: false,
                  allowImageFocalpointEdit: false
                },
                on: {
                  "upload-image-object": _vm.updateUploadFaviconImageObject
                }
              }),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.folder.corporateIdentity.faviconDesc")
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.folder.corporateIdentity.colors")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("color-picker", {
                    attrs: { color: _vm.corporateIdentity._customColor[0] },
                    on: { "update-color": _vm.updateBaseColor }
                  }),
                  _c("label", { staticClass: "pl-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "settings.folder.corporateIdentity.baseColorLabel"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.folder.corporateIdentity.baseColorDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("color-picker", {
                    attrs: { color: _vm.corporateIdentity._customColor[1] },
                    on: { "update-color": _vm.updateSecondColor }
                  }),
                  _c("label", { staticClass: "pl-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "settings.folder.corporateIdentity.secondColorLabel"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "settings.folder.corporateIdentity.secondColorDesc"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("color-picker", {
                    attrs: { color: _vm.corporateIdentity._customColor[2] },
                    on: { "update-color": _vm.updateThirdColor }
                  }),
                  _c("label", { staticClass: "pl-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "settings.folder.corporateIdentity.thirdColorLabel"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.folder.corporateIdentity.thirdColorDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("color-picker", {
                    attrs: { color: _vm.corporateIdentity._customColor[3] },
                    on: { "update-color": _vm.updateFourthColor }
                  }),
                  _c("label", { staticClass: "pl-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "settings.folder.corporateIdentity.fourthColorLabel"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "settings.folder.corporateIdentity.fourthColorDesc"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.folder.corporateIdentity.typographyLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _vm._l(_vm.fonts.slice(0, _vm.maxFonts), function(font, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "position-relative mb-4" },
                  [
                    _c("label", { staticClass: "mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `settings.folder.corporateIdentity.font${index +
                                1}Label`
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("b-form-text", { staticClass: "pb-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `settings.folder.corporateIdentity.font${index +
                                1}Desc`
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("b-form-group", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function({ ariaDescribedbyFont }) {
                              return [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "mb-1 text-uppercase",
                                    attrs: {
                                      "aria-describedby": ariaDescribedbyFont,
                                      name: `font${index + 1}Source`,
                                      value: "googleFonts"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeFontSource(
                                          "googleFonts",
                                          index + 1
                                        )
                                      }
                                    },
                                    model: {
                                      value: font.source,
                                      callback: function($$v) {
                                        _vm.$set(font, "source", $$v)
                                      },
                                      expression: "font.source"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "settings.folder.corporateIdentity.useGoogleFonts"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "mb-1 text-uppercase",
                                    attrs: {
                                      "aria-describedby": ariaDescribedbyFont,
                                      name: `font${index + 1}Source`,
                                      value: "systemFonts"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeFontSource(
                                          "systemFonts",
                                          index + 1
                                        )
                                      }
                                    },
                                    model: {
                                      value: font.source,
                                      callback: function($$v) {
                                        _vm.$set(font, "source", $$v)
                                      },
                                      expression: "font.source"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "settings.folder.corporateIdentity.useSystemFonts"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm.customGroupFonts.length > 0
                                  ? _c(
                                      "b-form-radio",
                                      {
                                        staticClass: "mb-1 text-uppercase",
                                        attrs: {
                                          "aria-describedby": ariaDescribedbyFont,
                                          name: `font${index + 1}Source`,
                                          value: "customFonts"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changeFontSource(
                                              "customFonts",
                                              index + 1
                                            )
                                          }
                                        },
                                        model: {
                                          value: font.source,
                                          callback: function($$v) {
                                            _vm.$set(font, "source", $$v)
                                          },
                                          expression: "font.source"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.folder.corporateIdentity.useCustomFonts"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        font.source === "customFonts"
                          ? _c(
                              "div",
                              { staticStyle: { width: "200px" } },
                              [
                                _c("b-form-select", {
                                  attrs: { options: _vm.customGroupFonts },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeFont($event, index + 1)
                                    }
                                  },
                                  model: {
                                    value: font.font,
                                    callback: function($$v) {
                                      _vm.$set(font, "font", $$v)
                                    },
                                    expression: "font.font"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        font.source === "systemFonts"
                          ? _c(
                              "div",
                              { staticStyle: { width: "200px" } },
                              [
                                _c("b-form-select", {
                                  attrs: { options: _vm.fontFamilyListSystem },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeFont($event, index + 1)
                                    }
                                  },
                                  model: {
                                    value: font.font,
                                    callback: function($$v) {
                                      _vm.$set(font, "font", $$v)
                                    },
                                    expression: "font.font"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        font.source === "googleFonts"
                          ? _c("font-picker", {
                              ref: `font-picker-${index + 1}`,
                              refInFor: true,
                              staticClass: "mb-2",
                              attrs: {
                                id: `font-picker-${index + 1}`,
                                "api-key":
                                  "AIzaSyCBBJElCVDbMFMth4hmBGIAzDp5bO8ozWY",
                                "active-font": font.font
                              },
                              on: {
                                change: function($event) {
                                  return _vm.changeFont($event, index + 1)
                                }
                              }
                            })
                          : _vm._e(),
                        index > 0 && _vm.fonts.length > 1
                          ? _c(
                              "b-button",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  variant: "outline-danger",
                                  size: "sm"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.removeFont(index)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.folder.corporateIdentity.removeFont"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.fonts.length < _vm.maxFonts
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: { click: _vm.addMoreFonts }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "settings.folder.corporateIdentity.addMoreFonts"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "enable-typo",
                        name: "enableExtendTypo",
                        size: "lg",
                        value: true,
                        "unchecked-value": false
                      },
                      on: { change: _vm.enableDisableExtendTypo },
                      model: {
                        value: _vm.corporateIdentity.enableExtendTypo,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.corporateIdentity,
                            "enableExtendTypo",
                            $$v
                          )
                        },
                        expression: "corporateIdentity.enableExtendTypo"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "settings.folder.corporateIdentity.typography.enableExtendTypoLabel"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("b-form-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "settings.folder.corporateIdentity.typography.enableExtendTypoDec"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _vm.corporateIdentity.enableExtendTypo
                ? _c("div", { staticClass: "typography-box" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("h1")
                          }
                        }
                      },
                      [
                        _vm._v(" Headline 1 (h1) "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("h2")
                          }
                        }
                      },
                      [
                        _vm._v(" Headline 2 (h2) "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("h3")
                          }
                        }
                      },
                      [
                        _vm._v(" Headline 3 (h3) "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("h4")
                          }
                        }
                      },
                      [
                        _vm._v(" Headline 4 (h4) "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("h5")
                          }
                        }
                      },
                      [
                        _vm._v(" Headline 5 (h5) "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("h6")
                          }
                        }
                      },
                      [
                        _vm._v(" Headline 6 (h6) "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("p")
                          }
                        }
                      },
                      [
                        _vm._v(" Paragraph (p) "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("customTypo1")
                          }
                        }
                      },
                      [
                        _vm._v(" Custom Typo 1 "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("customTypo2")
                          }
                        }
                      },
                      [
                        _vm._v(" Custom Typo 2 "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-2",
                        on: {
                          click: function($event) {
                            return _vm.openTypographyModal("customTypo3")
                          }
                        }
                      },
                      [
                        _vm._v(" Custom Typo 3 "),
                        _c(
                          "span",
                          { staticClass: "d-inline-block" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "gear-fill",
                                "aria-hidden": "true",
                                scale: ".8",
                                variant: "secondary"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm.showTypographyModal
                ? _c("modal-typography-settings", {
                    attrs: {
                      tag: _vm.tagForTypography,
                      fonts: _vm.fonts,
                      "current-selected-family": _vm.familyOfExtendedTypoTag(
                        _vm.tagForTypography
                      ),
                      typographyStyles: _vm.typographyStyles
                    },
                    on: { "update-styles": _vm.updateStyles }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                _vm._s(
                  _vm.$t("settings.folder.corporateIdentity.buttonLayoutLabel")
                )
              )
            ])
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "7" } }, [
            _c(
              "div",
              [
                _c(
                  "div",
                  { staticStyle: { height: "50px" } },
                  [
                    _c(
                      "b-button",
                      { style: _vm.corporateIdentity.buttons.styleObject },
                      [_vm._v("Button")]
                    )
                  ],
                  1
                ),
                _c("b-form-group", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function({ ariaDescribedbyShape }) {
                        return [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-shape-options",
                              options: _vm.selectedButtonShapeOptions,
                              "aria-describedby": ariaDescribedbyShape,
                              name: "radio-shape-options"
                            },
                            on: {
                              change: function($event) {
                                return _vm.buttonShapeChanged(
                                  $event,
                                  "buttonShape"
                                )
                              }
                            },
                            model: {
                              value:
                                _vm.corporateIdentity.buttons.layout
                                  .buttonShape,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.corporateIdentity.buttons.layout,
                                  "buttonShape",
                                  $$v
                                )
                              },
                              expression:
                                "corporateIdentity.buttons.layout.buttonShape"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("b-form-group", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function({ ariaDescribedbyStyle }) {
                        return [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "radio-style-options",
                              options: _vm.selectedButtonStyleOptions,
                              "aria-describedby": ariaDescribedbyStyle,
                              name: "radio-style-options"
                            },
                            on: {
                              change: function($event) {
                                return _vm.buttonShapeChanged(
                                  $event,
                                  "buttonStyle"
                                )
                              }
                            },
                            model: {
                              value:
                                _vm.corporateIdentity.buttons.layout
                                  .buttonStyle,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.corporateIdentity.buttons.layout,
                                  "buttonStyle",
                                  $$v
                                )
                              },
                              expression:
                                "corporateIdentity.buttons.layout.buttonStyle"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }